<template>
  <div style="height:100vh:overflow:hidden;overflow-y:scroll">
    <titleLink title="疫情信息" :onClick="onClickBack"></titleLink>
    <div
      style="
        padding: 0.3rem 0.2rem 3rem 0.2rem;
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
        margin-top: 1rem;
      "
    >
      <card>
        <template #content>
          <van-row style="text-align: center">
            <van-col span="12">
              <div
                :class="tab == 1 ? 'btn active' : 'btn'"
                @click="switchTab(1)"
              >
                地区风险
              </div>
            </van-col>
            <van-col span="12">
              <div
                :class="tab == 2 ? 'btn active' : 'btn'"
                @click="switchTab(2)"
              >
                全国数据
              </div>
            </van-col>
          </van-row>
        </template>
      </card>
      <card v-if="tab === 1">
        <template #content>
          <van-row
            style="padding-top: 0px; line-height: 15px"
            @click="showAreaSelect"
          >
            <van-col span="24">
              <span class="plain-txt">所在地区：{{ province }}{{ city }}</span>
            </van-col>
            <van-col span="24">
              <span class="info-txt"
                >统计截至：{{ data.ncov2.diseaseh5Shelf.lastUpdateTime }}</span
              >
            </van-col>
          </van-row>
          <van-grid style="margin-top: 0.2rem">
            <van-grid-item v-for="(item, idx) in provinceCards" :key="idx">
              <dataCell
                :color="item.color"
                :bgColor="item.bgColor"
                :title="item.title"
                :value.sync="item.value"
                :badge.sync="item.badge"
              ></dataCell>
            </van-grid-item>
          </van-grid>
        </template>
      </card>
      <!-- <card v-if="tab === 1">
        <template #content>
          <van-row style="line-height: 15px">
            <span class="plain-txt">近期31省区市本土病例</span>
          </van-row>
          <van-row style="line-height: 20px">
            <van-col span="18">
              <span class="tip-txt"
                >高风险地区<span class="high-risk">{{
                  data.ncov3.data.hcount
                }}</span
                >个</span
              >
              <span class="tip-txt"
                >中风险地区<span class="mid-risk">{{
                  data.ncov3.data.mcount
                }}</span
                >个</span
              >
            </van-col>
            <van-col span="6" @click="toRiskPage()">
              <span style="color: #317cff; text-align: right" class="tip-txt"
                >查看详情></span
              >
            </van-col>
          </van-row>
          <riskAreaDataTable
            style="margin-top: 0.2rem"
            :data="data.ncov4.statisGradeCityDetail"
          ></riskAreaDataTable>
        </template>
      </card> -->
      <card v-if="tab === 2">
        <template #content>
          <van-row style="line-height: 15px">
            <span class="info-txt"
              >统计截至：{{ data.ncov2.diseaseh5Shelf.lastUpdateTime }}</span
            >
          </van-row>
          <van-grid :column-num="3" style="margin-top: 0.2rem">
            <van-grid-item v-for="(item, idx) in cards" :key="idx">
              <dataCell
                :color="item.color"
                :bgColor="item.bgColor"
                :title="item.title"
                :value.sync="item.value"
                :badge.sync="item.badge"
              ></dataCell>
            </van-grid-item>
          </van-grid>
        </template>
      </card>
      <card v-if="tab === 2">
        <template #content>
          <van-row style="line-height: 15px">
            <van-col span="8">
              <span class="plain-txt">全国疫情</span>
            </van-col>
          </van-row>
          <provinceTable
            :data="data.ncov2.areaTree[0].children"
            style="margin-top: 0.2rem"
          ></provinceTable>
        </template>
      </card>
      <card style="margin-top: 0.2rem">
        <template #content>
          <news :data="data.ncov1.news"></news>
        </template>
      </card>
    </div>
    <van-action-sheet v-model="showArea">
      <van-area
        title="选择区域"
        :area-list="areaList"
        columns-num="1"
        :columns-placeholder="['请选择', '请选择']"
        @confirm="areaConfirm"
        @cancel="showArea = false"
      />
    </van-action-sheet>
  </div>
</template>
<script>
import card from "@/views/components/Card";
import titleLink from "@/views/components/TitleLink";
import dataCell from "./dataCell.vue";
import provinceTable from "./areaDataTable.vue";
import news from "./newsList.vue";
import riskAreaDataTable from "./riskAreaDataTable.vue";
import api from "@/services/apis";
import remoteLoad from "@/utils/remoteload.js";
import { areaList } from "@vant/area-data";
import { Toast } from "vant";
export default {
  components: {
    card,
    titleLink,
    dataCell,
    provinceTable,
    news,
    riskAreaDataTable,
  },
  data() {
    return {
      data: {
        ncov1: {
          news: [],
        },
        ncov2: {
          diseaseh5Shelf: {
            areaTree: [
              {
                children: [],
              },
            ],
            chinaAdd: {},
            chinaTotal: {},
          },
        },
        ncov3: {
          data: {
            hcount: 0,
            mcount: 0,
          },
        },
        ncov4: {
          statisGradeCityDetail: [],
        },
      },
      provinceData: [],
      lastUpdateTime: "",
      curProvinceData: [],
      city: "",
      province: "",
      ori_city: "",
      ori_province: "",
      chinaDayList: [],
      chinaDayAddList: [],
      provinceCompare: {},
      localConfirm: 0,
      localConfirmAdd: 0,
      nowConfirm: 0,
      nowConfirmAdd: 0,
      confirm: 0,
      confirmAdd: 0,
      noInfect: 0,
      noInfectAdd: 0,
      importedCase: 0,
      importedCaseAdd: 0,
      dead: 0,
      deadAdd: 0,
      tab: 1,
      provinceRiskAreas: [[], []],
      tabs: [
        {
          title: "地区数据",
          type: 1,
        },
        {
          title: "全国数据",
          type: 2,
        },
      ],
      cards: [
        {
          title: "本土现有确诊",
          value: 0,
          badge: 0,
          color: "#e57631",
          bgColor: "#fffaf7",
        },
        {
          title: "现有确诊",
          color: "#e61c1d",
          value: 0,
          badge: 0,
          bgColor: "#fff8f8",
        },
        {
          title: "累计确诊",
          value: 0,
          badge: 0,
          color: "#be2121",
          bgColor: "#fff4f4",
        },
        {
          title: "无症状感染者",
          value: 0,
          badge: 0,
          color: "#ae3ac6",
          bgColor: "#fef7ff",
        },
        {
          title: "境外输入",
          value: 0,
          badge: 0,
          color: "#4e8be6",
          bgColor: "#f1f5ff",
        },
        {
          title: "累计死亡",
          value: 0,
          badge: 0,
          color: "#4e5a65",
          bgColor: "#f3f6f8",
        },
      ],
      provinceCards: [
        {
          title: "现有确诊",
          color: "#e61c1d",
          value: 0,
          badge: 0,
          bgColor: "#fff8f8",
        },
        {
          title: "本土无症状",
          value: 0,
          badge: 0,
          color: "#ae3ac6",
          bgColor: "#fef7ff",
        },
        {
          title: "累计确诊",
          value: 0,
          badge: 0,
          color: "#be2121",
          bgColor: "#fff4f4",
        },
        {
          title: "累计死亡",
          value: 0,
          badge: 0,
          color: "#4e5a65",
          bgColor: "#f3f6f8",
        },
      ],
      showArea: false,
      areaList: areaList,
    };
  },
  created() {
    const _self = this;
    api.yiqing_info().then((res) => {
      this.data = res.data;
      this.provinceData = this.data.ncov2.diseaseh5Shelf.areaTree[0].children;
      this.provinceCompare = this.data.ncov2.provinceCompare;
      this.lastUpdateTime = this.data.ncov2.lastUpdateTime;
      _self.fillData();
      if (window.AMap && window.AMapUI) {
        _self.getLngLatAddress();
      } else {
        Promise.all([
          remoteLoad(
            `https://webapi.amap.com/maps?v=1.4.15&key=74bfabca263c5d85fc9d7459d275457e&plugin=AMap.Geolocation,AMap.Geocoder,AMap.PlaceSearch`
          ),
        ]).then(() => {
          _self.getLngLatAddress();
        });
      }
    });
  },
  methods: {
    onClickBack() {
      this.$router.push("/");
    },
    showAreaSelect() {
      this.showArea = true;
    },
    areaConfirm(data) {
      if (!data[0].name) {
        Toast("请选择区域");
        return;
      }
      const province = data[0].name;
      if (data[1]) {
        const city = data[1].name;
        this.city = city;
      }

      this.city = "";
      this.province = this.cleanProvince(province);

      this.filterProvinceData(this.province);
      this.fillProvinceData();
      this.showArea = false;
    },
    cleanProvince(name) {
      if (name.indexOf("新疆") > -1) {
        return "新疆";
      }
      if (name.indexOf("西藏") > -1) {
        return "西藏";
      }
      if (name.indexOf("内蒙古") > -1) {
        return "内蒙古";
      }
      if (name.indexOf("广西") > -1) {
        return "广西";
      }
      if (name.indexOf("宁夏") > -1) {
        return "宁夏";
      }
      if (name.indexOf("香港") > -1) {
        return "香港";
      }
      if (name.indexOf("澳门") > -1) {
        return "澳门";
      }

      if (name.indexOf("北京") > -1) {
        return "北京";
      }
      if (name.indexOf("天津") > -1) {
        return "天津";
      }
      if (name.indexOf("上海") > -1) {
        return "上海";
      }
      if (name.indexOf("重庆") > -1) {
        return "重庆";
      }
      return name.replace("省", "");
    },
    cleanCity(name) {
      return name.replace("市", "");
    },
    isVipCity(city) {
      return (
        city == "北京市" ||
        city == "天津市" ||
        city == "上海市" ||
        city == "重庆市"
      );
    },
    toRiskPage() {
      this.$router.push({
        path: "/yiqing/riskarea",
        query: { province: this.ori_province, city: this.ori_city },
      });
    },
    switchTab(tab) {
      this.tab = tab;
    },
    async get_yi_qing_high_risk() {
      const res = await api.get_yi_qing_high_risk();
    },
    fillData() {
      this.cards[0]["value"] =
        this.data.ncov2.diseaseh5Shelf.chinaTotal.localConfirm;
      this.cards[0]["badge"] =
        this.data.ncov2.diseaseh5Shelf.chinaAdd.localConfirmH5;
      this.cards[1]["value"] =
        this.data.ncov2.diseaseh5Shelf.chinaTotal.nowConfirm;
      this.cards[1]["badge"] =
        this.data.ncov2.diseaseh5Shelf.chinaAdd.nowConfirm;
      this.cards[2]["value"] =
        this.data.ncov2.diseaseh5Shelf.chinaTotal.confirm;
      this.cards[2]["badge"] = this.data.ncov2.diseaseh5Shelf.chinaAdd.confirm;
      this.cards[3]["value"] =
        this.data.ncov2.diseaseh5Shelf.chinaTotal.noInfect;
      this.cards[3]["badge"] = this.data.ncov2.diseaseh5Shelf.chinaAdd.noInfect;
      this.cards[4]["value"] =
        this.data.ncov2.diseaseh5Shelf.chinaTotal.importedCase;
      this.cards[4]["badge"] =
        this.data.ncov2.diseaseh5Shelf.chinaAdd.importedCase;
      this.cards[5]["value"] = this.data.ncov2.diseaseh5Shelf.chinaTotal.dead;
      this.cards[5]["badge"] = this.data.ncov2.diseaseh5Shelf.chinaAdd.dead;
    },
    filterProvinceData() {
      this.curProvinceData = [];
      this.data.ncov2.diseaseh5Shelf.areaTree[0].children.forEach((ele) => {
        if (this.province.includes(ele.name)) {
          this.curProvinceData.push(ele);
        }
      });
    },
    fillProvinceData() {
      const data = this.curProvinceData[0];
      const provinceDiff = this.provinceCompare[this.province];
      this.provinceCards[0].value = data.total.nowConfirm;
      this.provinceCards[0].badge = provinceDiff.nowConfirm;
      this.provinceCards[1].value = data.total.wzz;
      this.provinceCards[1].badge = data.today.wzz_add;
      this.provinceCards[2].value = data.total.confirm;
      this.provinceCards[2].badge = provinceDiff.confirmAdd;
      this.provinceCards[3].value = data.total.dead;
      this.provinceCards[3].badge = provinceDiff.dead;
    },
    getLngLatAddress() {
      const _self = this;
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = [position.coords.longitude, position.coords.latitude];
          // eslint-disable-next-line
          AMap.plugin("AMap.Geocoder", function () {
            // eslint-disable-next-line
            var geocoder = new AMap.Geocoder({
              city: "010", //城市设为北京，默认：“全国”
              radius: 1000, //范围，默认：500
            });
            geocoder.getAddress(pos);
            // eslint-disable-next-line
            AMap.event.addListener(geocoder, "complete", onComplete);
            // eslint-disable-next-line
            AMap.event.addListener(geocoder, "error", onError);

            function onComplete(data) {
              _self.city = data.regeocode.addressComponent.city.replace(
                "市",
                ""
              );
              _self.province = _self.cleanProvince(
                data.regeocode.addressComponent.province
              );
              _self.ori_city = data.regeocode.addressComponent.city;
              _self.ori_province = data.regeocode.addressComponent.province;
              _self.filterProvinceData(_self.province);
              _self.fillProvinceData();
            }

            function onError(data) {
              console.log(data);
              _self.getLngLatAddress();
            }
          });
        },
        (err) => {
          console.log(err);
          // _self.getLngLatAddress();
        }
      );
    },
    amapGeocoder(pos) {
      const _self = this;
      // eslint-disable-next-line
      AMap.plugin("AMap.Geocoder", function () {
        // eslint-disable-next-line
        var geocoder = new AMap.Geocoder({
          city: "010", //城市设为北京，默认：“全国”
          radius: 1000, //范围，默认：500
        });
        geocoder.getAddress(pos);
        // eslint-disable-next-line
        AMap.event.addListener(geocoder, "complete", onComplete);
        // eslint-disable-next-line
        AMap.event.addListener(geocoder, "error", onError);

        function onComplete(data) {
          _self.city = data.regeocode.addressComponent.city.replace("市", "");
          _self.province = _self.cleanProvince(
            data.regeocode.addressComponent.province
          );
          _self.ori_city = data.regeocode.addressComponent.city;
          _self.ori_province = data.regeocode.addressComponent.province;
          _self.filterProvinceData(_self.province);
          _self.fillProvinceData();
        }

        function onError(data) {
          console.log(data);
          _self.getLngLatAddress();
        }
      });
    },
  },
};
</script>

<style scoped>
.btn {
  font-size: 15px;
  border-radius: 15px;
  width: 80%;
  margin-left: 10%;
  height: 30px;
  padding-top: 5px;
}
.active {
  background-color: rgb(57, 94, 232);
  color: #fff;
}
.plain-txt {
  font-size: 15px;
  font-weight: bold;
}
.info-txt {
  color: #666666;
  font-size: 12px;
}
.tip-txt {
  font-size: 15px;
}
.high-risk {
  color: #e61c1d;
}
.mid-risk {
  color: #ae3ac6;
}
</style>